<template>
  <div id="subpage-content">
    <CompanyDrawer />
    <div id="rcol">
      <v-card id="rcol-incard">
        <!-- Сертификаты -->
        <h1 id="naming">{{ $t("message.Sertif") }}</h1>
        <div id="about-card">
          <v-col v-for="item in items" :key="item.id" :cols="item.id">
            <v-card id="lic-card">
              <div id="sertif">
                <v-dialog v-model="dialog1" width="900">
                  <template v-slot:activator="{ on }">
                    <img height="200" width="150" v-on="on" :src="getImageUrl(item)" aspect-ratio="1" class="grey lighten-2" />
                  </template>
                  <v-card>
                    <img :src="getImageUrl(item)" />
                  </v-card>
                </v-dialog>
              </div>
              <div id="lic-card-text">
                <div id="incard-title">
                  {{ getItemName(item) }}
                </div>
                <br />
                {{ getItemText(item) }}
              </div>
            </v-card>
          </v-col>
          <!-- <v-card id="lic-card">
            <div id="sertif">
              <v-dialog v-model="dialog4" width="900">
                <template v-slot:activator="{ on }">
                  <v-img
                    height="100"
                    width="150"
                    v-on="on"
                    :src="getImageUrlbyName('Sert1')"
                    aspect-ratio="1"
                    class="grey lighten-2"
                  ></v-img>
                </template>
                <v-card>
                  <v-img :src="getImageUrlbyName('Sert1')"> </v-img>
                </v-card>
              </v-dialog>
            </div>
            <div id="lic-card-text">
              <div id="incard-title">
                {{ $t("message.SertCardTitle1") }}
              </div>
              <br />
              {{ $t("message.SertCardText1") }}
            </div>
          </v-card>
          <v-card id="lic-card">
            <div id="sertif">
              <v-dialog v-model="dialog5" width="900">
                <template v-slot:activator="{ on }">
                  <v-img
                    height="100"
                    width="150"
                    v-on="on"
                    :src="getImageUrlbyName('Sert2')"
                    aspect-ratio="1"
                    class="grey lighten-2"
                  ></v-img>
                </template>
                <v-card>
                  <v-img :src="getImageUrlbyName('Sert2')"> </v-img>
                </v-card>
              </v-dialog>
            </div>
            <div id="lic-card-text">
              <div id="incard-title">
                {{ $t("message.SertCardTitle2") }}
              </div>
              <br />
              {{ $t("message.SertCardText2") }}
            </div>
          </v-card>
          <v-card id="lic-card">
            <div id="sertif">
              <v-dialog v-model="dialog6" width="900">
                <template v-slot:activator="{ on }">
                  <v-img
                    height="200"
                    width="200"
                    v-on="on"
                    :src="getImageUrlbyName('Sert3')"
                    aspect-ratio="1"
                    class="grey lighten-2"
                  ></v-img>
                </template>
                <v-card>
                  <v-img :src="getImageUrlbyName('Sert3')"> </v-img>
                </v-card>
              </v-dialog>
            </div>
            <div id="lic-card-text">
              <div id="incard-title">
                {{ $t("message.SertCardTitle3") }}
              </div>
              <br />
              {{ $t("message.SertCardText3") }}
            </div>
          </v-card>
          <v-card id="lic-card">
            <div id="sertif">
              <v-dialog v-model="dialog7" width="900">
                <template v-slot:activator="{ on }">
                  <v-img
                    height="100"
                    width="150"
                    v-on="on"
                    :src="getImageUrlbyName('Sert4')"
                    aspect-ratio="1"
                    class="grey lighten-2"
                  ></v-img>
                </template>
                <v-card>
                  <v-img :src="getImageUrlbyName('Sert4')"> </v-img>
                </v-card>
              </v-dialog>
            </div>
            <div id="lic-card-text">
              <div id="incard-title">
                {{ $t("message.SertCardTitle4") }}
              </div>
              <br />
              {{ $t("message.SertCardText4") }}
            </div>
          </v-card>
          <v-card id="lic-card">
            <div id="sertif">
              <v-dialog v-model="dialog8" width="900">
                <template v-slot:activator="{ on }">
                  <v-img
                    height="100"
                    width="150"
                    v-on="on"
                    :src="getImageUrlbyName('Sert5')"
                    aspect-ratio="1"
                    class="grey lighten-2"
                  ></v-img>
                </template>
                <v-card>
                  <v-img :src="getImageUrlbyName('Sert6')"> </v-img>
                </v-card>
              </v-dialog>
            </div>
            <div id="lic-card-text">
              <div id="incard-title">
                {{ $t("message.SertCardTitle5") }}
              </div>
              <br />
              {{ $t("message.SertCardText5") }}
            </div>
          </v-card>
          <v-card id="lic-card">
            <div id="sertif">
              <v-dialog v-model="dialog9" width="900">
                <template v-slot:activator="{ on }">
                  <v-img
                    height="200"
                    width="200"
                    v-on="on"
                    :src="getImageUrlbyName('Sert6')"
                    aspect-ratio="1"
                    class="grey lighten-2"
                  ></v-img>
                </template>
                <v-card>
                  <v-img :src="getImageUrlbyName('Sert6')"> </v-img>
                </v-card>
              </v-dialog>
            </div>
            <div id="lic-card-text">
              <div id="incard-title">
                {{ $t("message.SertCardTitle6") }}
              </div>
              <br />
              {{ $t("message.SertCardText6") }}
            </div>
          </v-card>
          <v-card id="lic-card">
            <div id="sertif">
              <v-dialog v-model="dialog10" width="900">
                <template v-slot:activator="{ on }">
                  <v-img
                    height="200"
                    width="200"
                    v-on="on"
                    :src="getImageUrlbyName('Sert7')"
                    aspect-ratio="1"
                    class="grey lighten-2"
                  ></v-img>
                </template>
                <v-card>
                  <v-img :src="getImageUrlbyName('Sert7')"> </v-img>
                </v-card>
              </v-dialog>
            </div>
            <div id="lic-card-text">
              <div id="incard-title">
                {{ $t("message.SertCardTitle7") }}
              </div>
              <br />
              {{ $t("message.SertCardText7") }}
            </div>
          </v-card>
          <v-card id="lic-card">
            <div id="sertif">
              <v-dialog v-model="dialog11" width="900">
                <template v-slot:activator="{ on }">
                  <v-img
                    height="100"
                    width="150"
                    v-on="on"
                    :src="getImageUrlbyName('Sert8')"
                    aspect-ratio="1"
                    class="grey lighten-2"
                  ></v-img>
                </template>
                <v-card>
                  <v-img :src="getImageUrlbyName('Sert8')"> </v-img>
                </v-card>
              </v-dialog>
            </div>
            <div id="lic-card-text">
              <div id="incard-title">
                {{ $t("message.SertCardTitle8") }}
              </div>
              <br />
              {{ $t("message.SertCardText8") }}
            </div>
          </v-card> -->
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import CompanyDrawer from "../../../views/Navigations/Company/CompanyDrawer";
export default {
  data: () => ({
    openImgModal: false,
    dialog: false,
    items: [],
    // lic1: require("../../../assets/images/lic1.jpg"),
    // lic2: require("../../../assets/images/lic2.jpg"),
    // lic3: require("../../../assets/images/lic3.png"),
    // sert1: require("../../../assets/images/sert1.jpg"),
    // sert2: require("../../../assets/images/sert2.jpg"),
    // sert3: require("../../../assets/images/sert3.jpg"),
    // sert4: require("../../../assets/images/sert4.jpg"),
    // sert5: require("../../../assets/images/sert5.jpg"),
    // sert6: require("../../../assets/images/sert6.jpg"),
    // sert7: require("../../../assets/images/sert7.jpg"),
    // sert8: require("../../../assets/images/sert8.jpg"),
  }),
  created() {
    window.addEventListener("beforeunload", this.handler());
  },
  async mounted() {
    await this.getLicenseblock();
  },

  components: {
    CompanyDrawer,
  },
  methods: {
    async handler() {
      await this.$store.dispatch("getAllLicenseblocks");
      this.items = await this.$store.getters.getLicenseblocks.filter((item) => item.issertif);
    },
    openImg() {
      this.openImgModal = true;
    },
    async getLicenseblock() {
      // await this.$store.dispatch("getAllLicenseblocks");
      this.items = await this.$store.getters.getLicenseblocks.filter((item) => item.issertif);
      // this.items = this.items.sort(
      //   (b, a) => new Date(a.date) - new Date(b.date)
      // );
      // console.log(this.items);
    },
    getImageUrl(item) {
      return "data:image/jpeg;base64," + item.image;
    },
    getItemName(item) {
      if (this.$i18n.locale === "ru") {
        return item.name1;
      } else if (this.$i18n.locale === "gb") {
        return item.name2;
      }
    },
    getItemText(item) {
      if (this.$i18n.locale === "ru") {
        return item.text1;
      } else if (this.$i18n.locale === "gb") {
        return item.text2;
      }
    },
  },
};
</script>
<style>
#lic-card {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
#sertif {
  padding: 10px;
  display: flex;
  align-items: center;
  width: 25%;
}
#lic-card-text {
  width: 75%;
  padding: 15px;
  margin-left: 15px;
  font: 13px/20px Ubuntu, Arial, sans-serif;
}
@media (max-width: 860px) {
  #lic-card {
    height: 450px;
    width: 400px;
    display: flex;
    flex-direction: column;
  }
  #sertif {
    width: 100%;
  }
}
@media (max-width: 480px) {
  #lic-card {
    height: 550px;
    width: 230px;
  }
}
</style>
